import React from 'react';
import './footer.css';
import {FaInstagram} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footercontent">
        <div className="footertext">
          <p>© 2024 Kappa Alpha Pi – Omicron Chapter. All rights reserved.</p>
        </div>
        <div className="insta">
          <a href="https://www.instagram.com/kapiusc/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={30} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;