import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './header.css';
import logo from './kapilogo.PNG';

const Header = ({backgroundImage,title}) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isAboutExpanded, setIsAboutExpanded] = useState(false);

  const toggleMenu = () => {
    setIsNavExpanded(!isNavExpanded);
    if (isAboutExpanded) setIsAboutExpanded(false);
  };

  const closeMenu = () => {
    setIsNavExpanded(false);
    setIsAboutExpanded(false);
  };

  const headerStyle = {
    backgroundImage: `url(${backgroundImage})`
  };

  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    setShowHeader(true);
  }, []);

  return (
    <div className="header-wrapper">
      <CSSTransition in={showHeader} timeout={500} classNames="fade" unmountOnExit>
      <div className="header" style={headerStyle}>
        <Link to="/what-we-do">
          <div className="logo">
            <img src={logo} alt="KAPI Logo" />
          </div>
        </Link>
        {title && <h1 className="header-title">{title}</h1>}
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
        <nav className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
          <div className={`dropdown ${isAboutExpanded ? "show" : ""}`}>
            <button className="dropbtn" onClick={() => setIsAboutExpanded(!isAboutExpanded)}>ABOUT</button>
            <div className="dropdown-content">
              <Link to="/what-we-do" onClick={closeMenu}>WHAT WE DO</Link>
              <Link to="/history" onClick={closeMenu}>HISTORY</Link>
              <Link to="/why-kapi" onClick={closeMenu}>WHY KAPI</Link>
            </div>
          </div>
          <Link to="/membership" onClick={closeMenu}>MEMBERSHIP</Link>
          <Link to="/contact" onClick={closeMenu}>CONTACT</Link>
        </nav>
      </div>
      </CSSTransition>
    </div>
  );
};

export default Header;