import React, { useState, useRef } from 'react';
import Header from '../../components/header/header';
import banner from './banner.png';
import emailjs from '@emailjs/browser'
import './contact.css';

const Contact = () => {
  const form = useRef();
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [buttonText, setButtonText] = useState('Submit'); // State to hold the button text
  const [buttonDisabled, setButtonDisabled] = useState(false); // State to disable the button
  const [buttonClass, setButtonClass] = useState('submit-button'); // State for button class

  const [formData, setFormData] = useState({
    user_first_name: '',
    user_last_name: '',
    user_email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (submissionStatus !== '') {
      setSubmissionStatus('');
      setButtonText('Submit'); // Reset button text
      setButtonDisabled(false); // Enable the button
      setButtonClass('submit-button');
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonText('Submitting...'); // Change button text to "Submitting..."
    setButtonDisabled(true); // Disable button to prevent multiple submissions

    emailjs.sendForm('service_9waiate', 'template_efvw938', form.current, 'xNlVTfpfOnU7pM6ni')
      .then((result) => {
          console.log(result.text);
          setFormData({
            user_first_name: '',
            user_last_name: '',
            user_email: '',
            subject: '',
            message: ''
          }); // Clear the form fields
          e.target.reset();
          setSubmissionStatus('Message sent successfully!'); // Update submission status
          setButtonText('Submitted'); // Change button text to "Submitted"
          setButtonClass('submit-button-submitted');
          setButtonDisabled(true); // Keep the button disabled
      }, (error) => {
          console.log(error.text);
          setSubmissionStatus('Failed to send message, please try again.');
          setButtonText('Submit'); // Reset button text
          setButtonDisabled(false); // Enable the button
      });
  };

  return (
    <div>
      <Header backgroundImage={banner} title="CONTACT"/>
      <div className="text-area">
        <p>For any questions, please reach out to us at <a href="mailto:usckapi@gmail.com">usckapi@gmail.com</a>.</p>
        <p>Feel free to reach out to us on any of our social media platforms as well!</p>
        <p>Otherwise, please include your comments and concerns in the form below:</p>
      </div>
      <div className="contact-form-container">
        <form ref = {form} onSubmit={sendEmail} className="contact-form">
        <div className="name-fields">
          <input 
            type="text" 
            name="user_first_name" 
            value={formData.firstName} 
            onChange={handleChange} 
            placeholder="First Name" 
            required 
          />
          <input 
            type="text" 
            name="user_last_name" 
            value={formData.lastName} 
            onChange={handleChange} 
            placeholder="Last Name" 
            required 
          />
        </div>
          <input 
            type="email" 
            name="user_email" 
            value={formData.email} 
            onChange={handleChange} 
            placeholder="Email Address" 
            required 
          />
          <input 
            type="text" 
            name="subject" 
            value={formData.subject} 
            onChange={handleChange} 
            placeholder="Subject" 
            required 
          />
          <textarea 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            placeholder="Your Message" 
            required 
          />
          <button type="submit" disabled={buttonDisabled} className={buttonClass}>
            {buttonText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;