import React from 'react';
import Header from '../../components/header/header';
import './history.css'
import banner from './banner.png'
import ucsd from './ucsd.png'
import michigan from './michigan.png'
import emory from './emory.png'
import ucb from './ucb.png'
import chapman from './chapman.png'
import cornell from './cornell.png'
import ucla from './ucla.png'

const chapters = [
  { image: ucla, school: 'UC Los Angeles'},
  { image: cornell, school: 'Cornell University'},
  { image: ucsd, school: 'UC San Diego'},
  { image: emory, school: 'Emory University'},
  { image: chapman, school: 'Chapman University'},
  { image: ucb, school: 'UC Berkeley'}
];

const History = () => {
  return (
    <div>
      <Header backgroundImage={banner} title="HISTORY"/>

      {/* Section 1: History and Origins */}
      <div className="history-section">
        <div className="history-text">
          <h2>HISTORY OF KAPPA ALPHA PI NATIONAL FRATERNITY</h2>
          <img src={michigan} alt="University of Michigan Campus" className="campus-image" />
        </div>
        <div className="origin-text">
          <h3>WHERE IT BEGAN...</h3>
          <p>Kappa Alpha Pi was founded in the University of Michigan in 2007 by a group of like-minded and ambitious pre-law undergraduate students, who disaffiliated from Phi Alpha Delta Pre-Law Fraternity out of a desire for a more intimate community. Today, KAPi has grown into a respected network of over 11 dynamic chapters at prestigious institutions nationwide.</p>
          <p>During the inaugural meeting of the fraternity on October 9, 2007, the name Kappa Alpha Pi was carefully chosen to embody the organization's values and aspirations. Each of these letters carries profound significance, symbolizing the fundamental principles that still serve as guiding lights for KAPi's members. The term "Kappa," derived from the Greek word "κακουχία" (signifying hardship), serves as a poignant reminder of the challenges that united the founding members and their unwavering resolve to establish a new fraternity. "Alpha," borrowed from the Greek word "αδελφότητα" (representing brotherhood), commemorates the enduring bonds of friendship and camaraderie that have propelled the fraternity forward, allowing it to thrive and triumph over adversity. Lastly, "Pi," taken from the Greek word "πρόοδο" (meaning progress), stands as a powerful testament to the fraternity's dedication to perpetual growth and enhancement for the betterment of both present and future members, and society at large.</p>
        </div>
      </div>

      {/* Second Section: Chapter Images */}
      <h3 className="notablechapters">OTHER KAPI CHAPTERS NATIONWIDE INCLUDE: </h3>
      <div className="chapter-section">
        {chapters.map((chapter, index) => (
          <div className="chapter" key={index}>
            <img src={chapter.image} alt={chapter.school} className="chapter-image" />
            <div className="chapter-info">
              <h3>{chapter.school}</h3>
            </div>
          </div>
        ))}
      </div>

      <div className="omicron-section">
        <h2 className="omicron-heading">HISTORY OF THE OMICRON CHAPTER AT USC</h2>
        <p className="omicron-text">During the spring semester of 2023, students belonging to the USC Chapter of Phi Alpha Delta Pre-Law Fraternity encountered a difference of opinion related to their fraternity’s organizational structure and governance. In order to preserve the values and safeguard the best interests of their membership, a portion of the Fall 2022 pledge class made the decision to disassociate from Phi Alpha Delta and establish themselves as the inaugural USC Chapter of Kappa Alpha Pi Pre-Law, Co-Ed Fraternity. As a result, the USC Chapter of Kappa Alpha Pi was born, driven by a profound commitment to upholding the fundamental principles that underpin its existence and operation. We are committed to maintaining integrity, compassion, and professional competence throughout all our decision-making procedures, with the overarching aim of furthering the intellectual and moral advancement of our members. Additionally, we will apply these values externally as we cultivate meaningful relationships within both the local and on-campus communities.</p>
      </div>

    </div>
  );
};

export default History;