import React from 'react';
import Header from '../../components/header/header';
import { FaLinkedin } from 'react-icons/fa';
import './membership.css'
import banner from './banner.png'
import kristyn from './kristyn.png'
import sungjoon from './sungjoon (1).png'
import keleigh from './keleigh.png'
import sian from './sian.png'
import jerry from './jerry.png'
import dave from './dave (1).png'
import alphaclass from './alphaclass.jpeg'

const founders = [
  { image: sian, name: 'Sian Salazar', linkedin:"https://www.linkedin.com/in/siansalazar/", major:"Political Science, Global Health", minor: "French", add: "", hometown: "Livermore, CA"},
  { image: jerry, name: 'Jerry Li', linkedin:"https://www.linkedin.com/in/jerryjiyuli/",  major:"Public Policy, Philosophy", minor: "", add: "", hometown: "Hoboken, NJ"},
  { image: kristyn, name: 'Kristyn Zhang', linkedin:"https://www.linkedin.com/in/kristynzhang/",  major:"Accounting", minor: "", add: "Progressive Masters in Business Taxation", hometown: "Thousand Oaks, CA"},
  { image: sungjoon, name: 'Sungjoon Chang', linkedin:"https://www.linkedin.com/in/sungjoon-chang-880aa918a/",  major:"Philosophy, Politics, and Law", minor: "Classics", add: "", hometown: "Frankfurt, Germany"},
  { image: keleigh, name: 'Keleigh Luo', linkedin:"https://www.linkedin.com/in/keleighluo/",  major:" Law, History & Culture, Biochemistry", minor: "Management Consulting", add: "", hometown: " New York, NY"},
  { image: dave, name: 'David Lee', linkedin:"",  major:"Music Industry", minor: "Business Law, Artificial Intelligence Applications", add: "", hometown: "Los Angeles, CA"}
];

const Membership = () => {
  return (
    <div>
      <Header backgroundImage={banner} title="MEMBERSHIP"/>
      <h2 className = "alphaclassheader">ALPHA CLASS SPRING 2024</h2>
      <div className='alphaclassdiv'>
        <img src = {alphaclass} alt = "Alpha Class Spring 24" className = "alphaclass-image"/>
        <p className = "alphaclassnames">Rylan Giorgetta, Denisea Lovato, Teja Moe, Cameryn Wong, Mike Guo, Dane Sprague</p>
      </div>
      <h2 className="foundingheader">FOUNDING MEMBERS</h2>
      <div className="founding-section">
        {founders.map((founder, index) => (
          <div className="founding" key={index}>
            <img src={founder.image} alt={founder.name} className="founding-image" />
            <div className="founding-info">
            <h3 className="name-line">
                {founder.name}
                {founder.linkedin && (
                  <a href={founder.linkedin} target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={24} style={{ marginLeft: '0.5rem' }} />
                  </a>
                )}
              </h3>
              <p>Major: {founder.major}</p>
              {founder.minor && <p>Minor: {founder.minor}</p>}
              {founder.add && <p>{founder.add}</p>}
              <p>Hometown: {founder.hometown}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Membership;