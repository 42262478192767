import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Footer from './components/footer/footer';
import WhatWeDo from './pages/whatwedo/whatwedo';
import History from './pages/history/history';
import WhyKAPI from './pages/whykapi/whykapi';
import Membership from './pages/membership/membership';
import Contact from './pages/contact/contact';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<WhatWeDo />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/history" element={<History />} />
          <Route path="/why-kapi" element={<WhyKAPI />} />
          <Route path="membership" element={<Membership />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
