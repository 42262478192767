import React from 'react';
import Header from '../../components/header/header';
import banner from './banner.png';
import edification from './edification.jpeg';
import community from './community.jpeg';
import lawschool from './lawschool.jpeg';
import profdev from './profdev.jpeg';
import './whykapi.css';

const WhyKAPI = () => {
  return (
    <div>
      <Header backgroundImage={banner} title="WHY KAPPA ALPHA PI"/>

      {/* Section 1: Purpose */}
      <div className="section">
        <img src={profdev} alt="Professional Development" className="section-image" />
        <div className="section-text">
          <h2>Professional Development</h2>
          <p>Our law fraternity is dedicated to fostering the professional growth of our members within the legal sphere. We recognize the significance of ongoing education and career growth in the evolving field of law. To accomplish this objective, we are firmly committed to providing a comprehensive education that offers diverse opportunities for our members to enrich their legal expertise and knowledge. Through guest panels, interactive workshops, networking forums, and mentoring programs, we aim to create an inclusive environment where we connect students to legal practitioners that can exchange insights, share experiences, and stay updated on latest legal developments. Our overarching goal is not only to promote academic excellence but to ensure that our members are well-equipped to meet the challenges of their legal careers and make meaningful contributions to the broader legal community.</p>
        </div>
      </div>

      {/* Section 2: Developing Professionalism */}
      <div className="section reverse">
        <div className="section-text">
          <h2>Law School Preparation</h2>
          <p>The LSAT or Law School Admission Test, plays a pivotal role in the journey to becoming a legal professional. It serves as a critical benchmark for law school admissions, evaluating a student’s analytical, logical, and critical thinking abilities, all vital skills in the legal field. At our fraternity we place a high priority in helping our members prepare for the LSAT, recognizing its significance in going to the next level of education. Through comprehensive LSAT prep courses and access to practice materials and mock exams from established LSAT Prep Companies, we ensure that our members excel in this crucial exam. Moreover, we foster a supportive environment where members are encouraged to share their strategies, insights, and experiences, enabling them to approach the LSAT with confidence and achieve their desired outcomes.</p>
        </div>
        <img src={lawschool} alt="Law School Preparation" className="section-image" />
      </div>

      {/* Section 3: Providing Service */}
      <div className="section">
        <img src={community} alt="Fostering Community" className="section-image" />
        <div className="section-text">
          <h2>Fostering Community</h2>
          <p>Our law fraternity is dedicated to fostering a sense of community among our members. We believe that a strong and supportive community is essential for personal and professional growth. To achieve this, we curate a diverse range of events, encompassing both social and educational activities, to encourage interaction and foster a sense of camaraderie among our members. Moreover, we offer a mentorship program that enables seasoned members of the fraternity to provide guidance and assistance to those new to their legal journey. We pride ourselves on being a diverse community that forges relationships beyond the academics to form a tight-knit family-like bond. In the Kappa Alpha Pi Fraternity, there are no strangers; we warmly welcome and connect with every member, fostering a sense of belonging.</p>
        </div>
      </div>

      {/* Section 4: Fostering Fraternalism */}
      <div className="section reverse">
        <div className="section-text">
          <h2>Edification</h2>
          <p>At Kappa Alpha Pi USC, engaging in meaningful debate on important social and legal issues is a key component of the pledge experience. In doing so, members will develop critical thinking, research, and communication skills, in addition to grappling with difficult ethical considerations. The pledge class debate experience will allow members to be well prepared for the intellectual challenges they will face in law school and in their future legal careers.</p>
        </div>
        <img src={edification} alt="Edification" className="section-image" />
      </div>

    </div>
  );
};

export default WhyKAPI;