import React from 'react';
import Header from '../../components/header/header';
import banner from './banner.png';
import purpose from './purpose.jpeg';
import professionalism from './professionalism.jpeg';
import service from './service.jpeg';
import fraternalism from './fraternalism.jpeg';
import './whatwedo.css';

const WhatWeDo = () => {
  return (
    <div className="whatwedowrapper">
      <Header backgroundImage={banner} title="WHAT WE DO"/>

      {/* Section 1: Purpose */}
      <div className="sectionWWD">
        <img src={purpose} alt="Purpose" className="section-imageWWD" />
        <div className="section-textWWD">
          <h2>Purpose</h2>
          <p>The Kappa Alpha Pi Pre-Law Fraternity aims to create a tight knit community of like-minded individuals who have an interest in law. Kappa Alpha Pi at USC offers Trojans the opportunity to find a sense of belonging, develop as professionals, serve the Los Angeles community, and undergo intellectual and moral edification through the study of the law.</p>
        </div>
      </div>

      {/* Section 2: Developing Professionalism */}
      <div className="section reverseWWD">
        <div className="section-textWWD">
          <h2>Developing Professionalism</h2>
          <p>At KAPi, we aim to prepare our members for law school and the legal profession by exposing them to professionals in the field, as well as holding lively debates and meaningful discussions on contemporary issues. This fosters intellectual growth and provides a platform to explore diverse perspectives on the pressing matters of today.</p>
        </div>
        <img src={professionalism} alt="Professionalism" className="section-imageWWD" />
      </div>

      {/* Section 3: Providing Service */}
      <div className="sectionWWD">
        <img src={service} alt="Service" className="section-imageWWD" />
        <div className="section-textWWD">
          <h2>Providing Service</h2>
          <p>We believe everyone has the duty to help those around us. At KAPi, we adhere to this principle by engaging in philanthropic activities in Los Angeles, both legal and non-legal.</p>
        </div>
      </div>

      {/* Section 4: Fostering Fraternalism */}
      <div className="section reverseWWD">
        <div className="section-textWWD">
          <h2>Fostering Fraternalism</h2>
          <p>The foundation of KAPi builds from the close bonds between its members. As we strive to grow both professionally and intellectually, we cultivate enduring friendships, learning from each other along the way.</p>
        </div>
        <img src={fraternalism} alt="Fraternalism" className="section-imageWWD" />
      </div>

    </div>
  );
};

export default WhatWeDo;